:root {
  --sitecolor: #00FF00;
  --textcolor: #ffffff;
  --bgcolor-pri: #000000;
  --bgcolor-sec: #303338;
  --linkcolor: #ffffff;
  --linkcolor-hov: #00FF00;
  --s-slider-prev: url('/images/arrow-left-green.svg');
  --s-slider-next: url('/images/arrow-right-green.svg');
}
:root {
  --deskWidth: 1296px;
  --deskSpace: 4vw;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1279px) {
  :root {
    --deskSpace: 8vw;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 15px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1279px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    border-color: var(--sitecolor);
  }
}
.button:active {
  border-color: var(--sitecolor);
}
/* outfit-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  src: url('/extras/fonts/outfit-v11-latin-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/outfit-v11-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: var(--sitecolor);
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  line-height: 1.33333333;
}
.unit caption {
  display: none;
}
.flag {
  background: var(--sitecolor);
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 30px;
  position: relative;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding-bottom: calc(var(--spacePart) * 2);
  box-sizing: border-box;
  isolation: isolate;
}
.list--bullet li.is-intersecting:not(:last-child):after {
  height: calc(100%);
}
.list--bullet li:after {
  content: '';
  position: absolute;
  left: 8px;
  top: 4px;
  height: 0;
  width: 1px;
  background-color: var(--textcolor);
  z-index: -1;
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.1s;
}
.list--bullet li:before {
  content: '';
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid var(--textcolor);
  background-color: #000000;
  top: 4px;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
.cb-multimood,
.cb-expo,
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.cb-slides,
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  isolation: isolate;
}
.cb-slides .slide,
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
.cb-slides a,
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
.cb-slides a.null,
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
.cb-slides img,
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: var(--sitecolor);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: var(--sitecolor);
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  line-height: 1.42857143;
  color: var(--textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #000000;
  box-sizing: border-box;
  padding: 40px;
  --linkcolor: #ffffff;
  --linkcolor-hov: #00FF00;
  --sitecolor: #00FF00;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 24px;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--sitecolor);
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: var(--sitecolor);
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
  line-height: 1.42857143;
}
@media (hover: hover) and (pointer: fine) {
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    border-color: var(--sitecolor);
  }
}
#disp .foot input:active,
#disp .foot a:active {
  border-color: var(--sitecolor);
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #000000;
  padding: 5px 10px;
  background-color: var(--sitecolor);
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1279px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Outfit', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  padding: 5px 10px;
  appearance: none;
  border-bottom: 1px solid var(--textcolor);
  color: var(--textcolor);
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 8px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  height: 40px;
  border: 1px solid var(--textcolor);
  padding: 5px;
  border-bottom: 1px solid var(--textcolor);
  color: var(--textcolor);
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: left;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  .unit.form input.submit:hover,
  .unit.form input.submit:focus {
    border-color: var(--sitecolor);
  }
}
.unit.form input.submit:active {
  border-color: var(--sitecolor);
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    border-color: var(--sitecolor);
  }
}
.two-step-verification-container a:active {
  border-color: var(--sitecolor);
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.34680574vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
.cb-home,
#home {
  float: left;
  height: 50px;
}
.logo {
  width: auto;
  height: 100%;
}
.cb-page-title,
#head {
  float: left;
  width: 100%;
}
#edit .cb-page-title,
#edit #head {
  margin-top: calc(var(--spaceTotal) * 3);
}
#view .cb-page-title,
#view #head {
  margin-bottom: calc(var(--spaceUnit) * -1);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bgcolor-pri);
  color: var(--textcolor);
  overflow: hidden;
  hyphens: none;
  font-family: 'Outfit', sans-serif;
  font-size: clamp(0.875rem, 0.793rem + 0.41vw, 1.125rem);
  line-height: 1.33333333;
  letter-spacing: 0.02em;
}
#edit.cb-page-selected-layout2 .wrapper {
  padding-top: 124px;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  #edit.cb-page-selected-layout2 .wrapper {
    padding-bottom: 94px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.naviFooter .desk,
.section--footer .desk,
.section--header .desk {
  --deskWidth: 1490px;
}
.section--slider .desk {
  --deskWidth: 1800px;
  --deskMaxWidth: 100%;
}
.section--full .desk {
  --deskWidth: 100%;
  --deskMaxWidth: 100%;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.cb-pages {
  float: left;
  width: 100%;
}
.cb-page {
  float: left;
  width: 100%;
  position: relative;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.container {
  float: left;
  width: 100%;
}
.section--header {
  padding: 40px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 2000;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (max-width: 1600px) {
  .section--header.navi--hidden {
    transform: translateY(-100%);
  }
}
@media (max-width: 767px) {
  .section--header {
    padding: 25px 0;
  }
}
.container--head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container--navi {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.section--anthrazit {
  isolation: isolate;
}
.section--anthrazit:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #0F0F0F;
  z-index: -1;
  clip-path: polygon(32% 0, 38% 3%, 100% 0, 100% 98%, 93% 97%, 81% 100%, 0 96%, 0 2%);
}
.container--moodvideo {
  min-height: 100vh;
  min-height: 100svh;
  isolation: isolate;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: calc(var(--spaceTotal) * -3);
  padding-bottom: calc(var(--spaceTotal) * 2);
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .container--moodvideo {
    min-height: 80vh;
    min-height: 80svh;
  }
}
.container--moodvideo .moodvideo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  clip-path: polygon(0% 0, 30% 0%, 100% 0, 100% 96%, 56% 99%, 50% 94%, 0 98%, 0 0%);
}
.container--moodvideo .moodvideo__clip {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.container--moodvideo .content {
  width: 1200px;
  max-width: var(--deskMaxWidth);
}
.container--columns {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 60px;
  isolation: isolate;
  box-sizing: border-box;
  --s-textcolor: #ffffff;
}
@media (max-width: 1279px) {
  .container--columns {
    grid-template-columns: 1fr;
  }
}
.column {
  position: relative;
}
.column--one {
  padding-top: var(--deskSpace);
  box-sizing: border-box;
}
.container--columns.moodRight .column--one {
  padding-left: max(var(--deskSpace), calc((100vw - 1200px) / 2));
}
.container--columns.moodLeft .column--one {
  padding-right: max(var(--deskSpace), calc((100vw - 1200px) / 2));
}
@media (max-width: 1279px) {
  .column--one {
    padding: 0 var(--deskSpace);
  }
}
.column--two {
  aspect-ratio: 0.8;
}
.container--columns.moodLeft .column--two {
  order: -1;
  padding-left: max(0px, calc((100vw - 1800px) / 2));
}
.container--columns.moodRight .column--two {
  padding-right: max(0px, calc((100vw - 1800px) / 2));
}
@media (max-width: 1279px) {
  .column--two {
    width: 50%;
    margin-left: 50%;
    order: -1;
  }
}
@media (max-width: 767px) {
  .column--two {
    width: 70%;
    margin-left: 30%;
    order: -1;
  }
}
.designelement {
  position: absolute;
  bottom: 0;
  left: 0;
}
.designelement:not(.designelement--5) {
  bottom: clamp(48px, 33%, 160px);
  width: clamp(88px, 33%, 300px);
}
.container--columns.moodLeft .designelement:not(.designelement--5) {
  left: unset;
  right: 0;
}
@media (max-width: 1279px) {
  .container--columns.moodLeft .designelement:not(.designelement--5) {
    left: 0;
    right: unset;
  }
}
.designelement.designelement--5 {
  bottom: clamp(20px, 60%, 40px);
  width: clamp(200px, 60%, 600px);
  left: -50px;
}
.container--columns.moodLeft .designelement.designelement--5 {
  left: unset;
  right: -50px;
}
@media (max-width: 1279px) {
  .container--columns.moodLeft .designelement.designelement--5 {
    left: -50px;
    right: unset;
  }
}
.section--footer {
  flex-shrink: 0;
}
.footarea {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  display: grid;
  grid-template-columns: 4fr 1fr;
}
.footpart {
  float: left;
  width: 100%;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.footpart.init {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1279px) {
  .footpart.init {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}
.footpart.exit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1279px) {
  .footLinks {
    display: none;
  }
}
.langnavi .navi.sub1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  gap: 10px;
}
@media (max-width: 1279px) {
  body:not(.cb-toggle-target-active) .langnavi .navi.sub1 {
    opacity: 0;
  }
}
.langnavi .navi.sub1 > .item:not(.exit):after {
  content: '|';
  margin-left: 10px;
  font-family: 'urw-din-condensed', sans-serif;
  color: var(--linkcolor);
  font-weight: 900;
}
.langnavi .navi.sub1 > .item > .menu {
  font-weight: 900;
  font-family: 'urw-din-condensed', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
}
.langnavi .navi.sub1 > .item > .menu.path {
  color: var(--linkcolor-hov);
}
@media (hover: hover) and (pointer: fine) {
  .langnavi .navi.sub1 > .item > .menu:hover,
  .langnavi .navi.sub1 > .item > .menu:focus {
    color: var(--linkcolor-hov);
  }
}
.footLinks .navi.sub2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.footNav {
  float: left;
  width: 100%;
  flex-shrink: 0;
}
.socialWrapper {
  float: left;
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.socialWrapper .social {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  display: block;
  transition: background-color 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  width: 20px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-image: url('/images/instagram.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}
.socialWrapper .social--linkedin {
  mask-image: url('/images/linkedin.svg');
}
.socialWrapper .social--facebook {
  mask-image: url('/images/facebook.svg');
}
@media (hover: hover) and (pointer: fine) {
  .socialWrapper .social:hover,
  .socialWrapper .social:focus {
    background-color: var(--linkcolor-hov);
  }
}
.footlogo {
  width: clamp(120px, 50%, 220px);
  margin-right: 20px;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 48px;
  left: 48px;
  z-index: 1900;
  width: 500px;
  max-width: 100%;
  background-color: #000000;
  border: 1px solid #ffffff;
  font-family: 'Outfit', sans-serif;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
  padding: 30px 30px;
  box-sizing: border-box;
  transform: translateX(-200%) translateY(200%);
  transition: all 0.5s 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1279px) {
  #cb-cookie-warning {
    left: 0;
    bottom: 0;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    padding: 20px var(--deskSpace);
  }
}
.show-content #cb-cookie-warning {
  transform: translateX(0) translateY(0);
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  color: #ffffff;
  text-align: center;
  margin-bottom: calc(var(--spacePart) * 2);
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--spacePart) * 1);
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    justify-content: center;
  }
}
.cb-cookie-warning__button {
  float: left;
  cursor: pointer;
}
#cb-cookie-warning__button--decline {
  order: 2;
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
  opacity: 0.6;
}
@media (hover: hover) and (pointer: fine) {
  #cb-cookie-warning__button--decline:hover,
  #cb-cookie-warning__button--decline:focus {
    border-color: var(--sitecolor);
  }
}
#cb-cookie-warning__button--decline:active {
  border-color: var(--sitecolor);
}
#cb-cookie-warning__button--accept {
  order: 1;
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  #cb-cookie-warning__button--accept:hover,
  #cb-cookie-warning__button--accept:focus {
    border-color: var(--sitecolor);
  }
}
#cb-cookie-warning__button--accept:active {
  border-color: var(--sitecolor);
}
a {
  color: var(--linkcolor);
  text-decoration: none;
}
@media (hover: hover) and (pointer: fine) {
  a:hover,
  a:focus {
    color: var(--linkcolor-hov);
  }
}
.part.link .open {
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  .part.link .open:hover,
  .part.link .open:focus {
    border-color: var(--sitecolor);
  }
}
.part.link .open:active {
  border-color: var(--sitecolor);
}
.part.load .load {
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  .part.load .load:hover,
  .part.load .load:focus {
    border-color: var(--sitecolor);
  }
}
.part.load .load:active {
  border-color: var(--sitecolor);
}
h1 {
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
h2 {
  font-size: clamp(2.5rem, 1.27rem + 6.15vw, 6.25rem);
  font-family: 'urw-din-condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1;
}
h2 strong {
  font-weight: 900;
  color: var(--sitecolor);
}
h3 {
  font-size: clamp(2rem, 1.221rem + 3.89vw, 4.375rem);
  font-family: 'urw-din-condensed', sans-serif;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 900;
}
h4 {
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.area--one .unit:not(.unit--form) {
  text-align: center;
}
.area--one .unit:not(.unit--form) .cb-image-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.area--one .unitTwo h2 {
  color: var(--sitecolor);
}
.area--two {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.area--two .part.text .norm {
  padding-right: var(--deskSpace);
}
#view #head {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
#view #head.is-intersecting {
  opacity: 1;
  transform: translateX(0);
}
#view .unit .part {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
#view .unit .part.is-intersecting {
  opacity: 1;
  transform: translateX(0);
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.togglenavigation {
  position: relative;
  z-index: 2001;
  width: 56px;
  height: 44px;
  cursor: pointer;
  display: flex;
}
@media (max-width: 767px) {
  .togglenavigation {
    transform: scale(0.586);
    transform-origin: top left;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 50%;
  width: 56px;
  height: 4px;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 4px;
  transition: background-color 0.5s 0.4s, width 0.4s cubic-bezier(0.22, 1, 0.36, 1), transform 0.4s cubic-bezier(0.22, 1, 0.36, 1), top 0.5s 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
body.cb-toggle-target-active .tline {
  background-color: #000000;
}
.tline--1 {
  width: 26px;
}
.tline--2 {
  top: 10px;
}
.tline--32,
.tline--31 {
  top: 20px;
  width: 41px;
}
.tline--4 {
  top: 30px;
}
.tline--5 {
  top: 40px;
  width: 26px;
}
body.cb-toggle-target-active {
  --linkcolor: #000000;
  --linkcolor-hov: #ffffff;
}
body.cb-toggle-target-active .tline--1,
body.cb-toggle-target-active .tline--2,
body.cb-toggle-target-active .tline--4,
body.cb-toggle-target-active .tline--5 {
  width: 0;
}
body.cb-toggle-target-active .tline--2 {
  transform: translateX(-60%);
}
body.cb-toggle-target-active .tline--4 {
  transform: translateX(-40%);
}
body.cb-toggle-target-active .tline--31 {
  width: 100%;
  transform: translateX(-50%) rotate(45deg);
  transition: transform 0.5s 0.4s cubic-bezier(0.22, 1, 0.36, 1), top 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
body.cb-toggle-target-active .tline--32 {
  width: 100%;
  transform: translateX(-50%) rotate(-45deg);
  transition: transform 0.5s 0.4s cubic-bezier(0.22, 1, 0.36, 1), top 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: var(--sitecolor);
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.naviContent {
  float: left;
  width: 100%;
  display: flex;
  padding: 100px 0 50px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  gap: 20px;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
body.cb-toggle-target-active .naviContent {
  transition: all 1s 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  transform: translateY(0);
  opacity: 1;
}
.naviFooter {
  float: left;
  width: 100%;
}
.navigation {
  float: left;
  width: 100%;
  isolation: isolate;
}
.navigation__main {
  float: left;
  width: 100%;
  position: relative;
}
.navigation__second {
  margin-top: calc(var(--spaceTotal) * 1);
  float: left;
  width: 100%;
  position: relative;
}
.navigation .navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.navigation .navi > .cb-toggle {
  display: none;
}
.navigation .navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.navigation .navi > .item.item-empty > .cb-toggle {
  display: none;
}
.navigation .navi > .item > .menu {
  display: block;
  color: var(--linkcolor);
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (hover: hover) and (pointer: fine) {
  .navigation .navi > .item > .menu:hover,
  .navigation .navi > .item > .menu:focus {
    color: var(--linkcolor-hov);
  }
}
.navigation__main .navi.sub2 {
  background-size: clamp(280px, 50%, 480px);
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-image: url(/images/check-white.svg);
}
@media (max-width: 1279px) {
  .navigation__main .navi.sub2 {
    background-image: unset;
  }
}
.navigation__main .navi.sub2 > .item > .menu {
  font-size: clamp(3.438rem, 2.515rem + 4.61vw, 6.25rem);
  line-height: 1.1;
  font-family: 'urw-din-condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
}
.navigation__second .navi.sub2 {
  display: none;
  flex-direction: column;
  gap: 15px;
}
@media (max-width: 1279px) {
  .navigation__second .navi.sub2 {
    display: flex;
  }
}
.navigation__second .navi.sub2 > .item > .menu {
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.onepageNavi {
  position: fixed;
  z-index: 1000;
  left: max(calc(var(--deskSpace) + 20px), calc((100vw - 1450px) / 2));
}
@media (max-width: 1400px) {
  .onepageNavi {
    left: 2vw;
  }
}
@media (max-width: 1279px) {
  .onepageNavi {
    display: none;
  }
}
body:not(.cb-onepage) .onepageNavi {
  display: none;
}
.onepageNavi .navi.sub3 {
  display: flex;
  flex-direction: column;
  margin-top: 170px;
  min-height: calc(80vh - 170px);
  min-height: calc(80svh - 170px);
  gap: 50px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
.onepageNavi .navi.sub3:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 6px;
  height: calc(100% - 18px);
  width: 1px;
  background-color: var(--textcolor);
  z-index: -1;
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.1s;
}
.onepageNavi .navi.sub3 > .item {
  float: left;
  width: 100%;
  position: relative;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
.onepageNavi .navi.sub3 > .item.designEle--one {
  --designEle: url(/images/designelement-1-white.svg);
  --designEleHov: url(/images/designelement-1.svg);
}
.onepageNavi .navi.sub3 > .item.designEle--two {
  --designEle: url(/images/designelement-2-white.svg);
  --designEleHov: url(/images/designelement-2.svg);
}
.onepageNavi .navi.sub3 > .item.designEle--three {
  --designEle: url(/images/designelement-3-white.svg);
  --designEleHov: url(/images/designelement-3.svg);
  --designEleSize: 17px;
}
.onepageNavi .navi.sub3 > .item.designEle--four {
  --designEle: url(/images/designelement-4-white.svg);
  --designEleHov: url(/images/designelement-4.svg);
}
.onepageNavi .navi.sub3 > .item.path {
  flex-grow: 1;
}
.onepageNavi .navi.sub3 > .item > .menu {
  position: relative;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 47px;
  height: 19px;
  display: inline-block;
}
.onepageNavi .navi.sub3 > .item > .menu:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 19px;
  height: 26px;
  background-size: var(--designEleSize, 19px);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: var(--designEle);
}
.onepageNavi .navi.sub3 > .item > .menu:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid var(--textcolor);
  box-sizing: border-box;
  background-color: #000000;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (hover: hover) and (pointer: fine) {
  .onepageNavi .navi.sub3 > .item > .menu:hover:before,
  .onepageNavi .navi.sub3 > .item > .menu:focus:before {
    background-color: #00FF00;
  }
  .onepageNavi .navi.sub3 > .item > .menu:hover:after,
  .onepageNavi .navi.sub3 > .item > .menu:focus:after {
    background-image: var(--designEleHov);
  }
}
.onepageNavi .navi.sub3 > .item > .menu.path:before {
  background-color: #00FF00;
}
.onepageNavi .navi.sub3 > .item > .menu.path:after {
  background-image: var(--designEleHov);
}
.ns-slider--gallerySlider .ns-slider {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--gallerySlider .ns-slider__stage {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.ns-slider--gallerySlider .ns-slider__area {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
.ns-slider--gallerySlider .ns-slider__section {
  float: left;
  width: 100%;
}
.ns-slider--gallerySlider .ns-slider__cover {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.ns-slider--gallerySlider .ns-slide {
  float: left;
  width: 100%;
  flex-shrink: 0;
  transition: all 0.8s ease-in-out;
}
.ns-slider--gallerySlider .ns-slider__part {
  float: left;
  width: 100%;
}
.ns-slider--gallerySlider .ns-slider__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 2.25;
}
@media (max-width: 767px) {
  .ns-slider--gallerySlider .ns-slider__part--image img {
    aspect-ratio: 1;
  }
}
.ns-slider__controls {
  padding-right: max(var(--deskSpace), calc((100vw - 1280px) / 2));
  box-sizing: border-box;
  position: relative;
  float: right;
  z-index: 2;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: calc(var(--spacePart) * 1);
}
.ns-slider .ns-sliderControl {
  position: absolute;
  width: 92px;
  height: 92px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1279px) {
  .ns-slider .ns-sliderControl {
    width: 42px;
    height: 42px;
  }
}
.ns-slider .ns-sliderControl--prev {
  left: var(--deskSpace);
  background-image: var(--s-slider-prev);
}
.ns-slider .ns-sliderControl--next {
  right: var(--deskSpace);
  background-image: var(--s-slider-next);
}
.ns-sliderIndex {
  float: left;
  width: 100%;
  border-left: 1px solid var(--textcolor);
  border-right: 1px solid var(--textcolor);
  border-bottom: 1px solid var(--textcolor);
  box-sizing: border-box;
}
.ns-sliderIndex__content {
  display: flex;
  flex-direction: row;
}
.ns-sliderIndex__item {
  height: 17px;
  flex-grow: 1;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}
.ns-sliderIndex__item.is-active {
  background-color: var(--textcolor);
}
.ns-slider--claimSlider {
  position: relative;
  float: left;
  width: 100%;
}
.ns-slider--claimSlider .ns-slider__stage {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--claimSlider .ns-slider__area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "slides";
}
.ns-slider--claimSlider .ns-slide {
  grid-area: slides;
  float: left;
  width: 100%;
  position: relative;
  transition: all 2s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 1;
}
#edit .ns-slider--claimSlider .ns-slide:not(.is-active) {
  pointer-events: none;
}
.ns-slider--claimSlider .ns-slide.is-active {
  z-index: 2;
  opacity: 1;
}
.ns-slider--claimSlider .ns-slide__content {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider--claimSlider .ns-slider__part {
  float: left;
  width: 100%;
  transform: scale(1) rotateX(-90deg) translateY(-200%);
  opacity: 0;
  transition: all 0.5s;
  transform-origin: left center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}
.ns-slider--claimSlider .ns-slide.is-active + .ns-slide .ns-slider__part {
  opacity: 0;
  transform: scale(1) rotateX(90deg) translateY(200%);
}
.ns-slider--claimSlider .ns-slide.is-active .ns-slider__part {
  transition: all 0.4s 0.5s;
  opacity: 1;
  transform: scale(1) rotateX(0deg) translateY(0);
}
/*# sourceMappingURL=./screen-small.css.map */